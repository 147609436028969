const PtopApi = {
  post: async (url, params) => {
    let customerParams = getCustomerParams();
    params.token = customerParams.pToken;
    params.ptopId = customerParams.ptopId;
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json"
      }
    });

    let parsedResponse = await response.json();
    console.log(parsedResponse);
    return parsedResponse;
  },
  get: async (url, params) => {
    let response = await fetch(url + getQueryString(params), {
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      }
    });

    let parsedResponse = await response.json();
    console.log(parsedResponse);

    return parsedResponse;
  }
};

const getQueryString = params => {
  let customerParams = getCustomerParams();
  params.token = customerParams.pToken;
  params.ptopId = customerParams.ptopId;

  return `?${serializeQueryObject(params)}`;
};

let serializeQueryObject = queryObject => {
  return Object.keys(queryObject)
    .map(k => `${k}=${queryObject[k]}`)
    .join("&");
};

let getCustomerParams = () => {
  const query = new URLSearchParams(window.location.search);

  return {
    ptopId:
      (query.get("ptopId") && encodeURIComponent(query.get("ptopId"))) ||
      localStorage.getItem("ptopId"),
    pToken:
      (query.get("pToken") && encodeURIComponent(query.get("pToken"))) ||
      localStorage.getItem("pToken")
  };
};

export { PtopApi };
