let translation = {
  he: {
    months: {
      jan: "ינואר",
      feb: "פברואר",
      march: "מרץ",
      april: "אפריל",
      may: "מאי",
      june: "יוני",
      july: "יולי",
      august: "אוגוסט",
      sept: "ספטמבר",
      oct: "אוקטובר",
      nov: "נובמבר",
      dec: "דצמבר"
    },
    apartment_number: "מספר דירה",
    tenant_name: "שם הדייר",
    phone_number: "מספר טלפון",
    select_year: "בחר שנה לצפייה",
    quick_search: "חיפוש מהיר",
    payemnt_confirmation: "אישור תשלום",
    year_title: "טבלת תשלומים שנתית עבור",
    month_title: "טבלת תשלומים חודשית עבור",
    apartment_title: "טבלת תשלומים עבור דירה",
    payemnt_receipt: "קבלה",
    failed: "נכשל",
    success: "הצליח",
    ptop: "פיטופי:",
    apartment: {
      clientName: "שם לקוח",
      clientPhone: "טלפון",
      clientEmail: "כתובת מייל",
      apartmentNumber: "מספר דירה",
      PaymentPlanId: "אסמכתא",
      amount: "סכום תשלום ב-₪",
      cardLast4Digits: "ארבע ספרות כ.א",
      expdate: "תוקף כ.א",
      paymentDate: "תאריך חיוב",
      planType: "סוג תכנית",
      tenantType: "סוג דייר",
      status: "סטטוס",
      comments: "הערות"
    },
    month: {
      PaymentDate: "תאריך חיוב",
      ClientName: "שם הדייר",
      ApartmentNumber: "מספר דירה",
      PlanType: "סוג תכנית",
      Amount: "סכום תשלום ב-₪",
      PaymentPlanId: "אסמכתא",
      Comment: "הערות",
      TenantType: "סוג דייר"
    },
    tenants_list: {
      title: "רשימת פרטי דיירים",
      apartment_number: "מספר דירה",
      name: "שם דייר",
      phone: "טלפון",
      email: "מייל",
      fee: "סכום תשלום ב-₪"
    }
  }
};

export default translation;
