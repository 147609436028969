import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import { ReactComponent as ReportIcon } from "../assets/reporting-svgrepo-com.svg";
import { ReactComponent as PhoneIcon } from "../assets/phone-icon.svg";
import CustomerLogo from "./CustomerLogo";
// import Dashboard from "@material-ui/icons/Dashboard";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1
  }
});

let itemStyle = {
  borderRadius: 5,
  marginBottom: 20
};

export let typoProps = {
  style: { color: "white", fontFamily: "PtopFont", fontWeight: 300 }
};

class ResponsiveDrawer extends React.Component {
  state = {
    mobileOpen: false
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { classes } = this.props;

    const drawer = (
      <div>
        <div
          style={{
            backgroundImage:
              "url(https://i.pinimg.com/564x/08/f8/80/08f880e9965e24f04ea7d77115fd023d.jpg)",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "block",
            zIndex: 1,
            position: "absolute",
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "black",
            zIndex: 3,
            opacity: 0.7
          }}
        />
        <List style={{ zIndex: 4, color: "white", margin: 10 }}>
          <CustomerLogo />
          <Divider
            style={{
              backgroundColor: "white",
              marginBottom: 20,
              marginTop: 10
            }}
          />
          {/*<Link style={{ textDecoration: "none" }} to="/dashboard">*/}
          {/*<ListItem*/}
          {/*selected={window.location.pathname === "/dashboard"}*/}
          {/*style={itemStyle}*/}
          {/*button*/}
          {/*key={"Dashboard"}*/}
          {/*>*/}
          {/*<ListItemIcon style={{ color: "white" }}>*/}
          {/*<Dashboard style={{ fill: "white" }} />*/}
          {/*</ListItemIcon>*/}
          {/*<ListItemText*/}
          {/*style={{ textAlign: "right" }}*/}
          {/*primaryTypographyProps={typoProps}*/}
          {/*primary={"לוח בקרה"}*/}
          {/*/>*/}
          {/*</ListItem>*/}
          {/*</Link>*/}
          <Link style={{ textDecoration: "none" }} to="/">
            <ListItem
              selected={window.location.pathname === "/"}
              style={itemStyle}
              button
              key={"Payments Report"}
            >
              <ListItemIcon style={{ color: "white" }}>
                <ReportIcon style={{ fill: "white", height: 22 }} />
              </ListItemIcon>
              <ListItemText
                style={{ textAlign: "right" }}
                primaryTypographyProps={typoProps}
                primary={"דו״ח תשלומים"}
              />
            </ListItem>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/tenantsList">
            <ListItem
              selected={window.location.pathname === "/tenantsList"}
              style={itemStyle}
              button
              key={"Tenants List"}
            >
              <ListItemIcon style={{ color: "white" }}>
                <PhoneIcon style={{ fill: "white", height: 22 }} />
              </ListItemIcon>
              <ListItemText
                style={{ textAlign: "right" }}
                primaryTypographyProps={typoProps}
                primary={"רשימת דיירים"}
              />
            </ListItem>
          </Link>
        </List>
      </div>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={"left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <Hidden mdUp implementation="css">
            <MenuIcon
              style={{ margin: 10 }}
              onClick={this.handleDrawerToggle}
            />
          </Hidden>
          {this.props.children}
        </main>
      </div>
    );
  }
}

ResponsiveDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);
