import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const TableHeadline = props => {
  return (
    <Card
      style={{
        marginBottom: 20,
        background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
        color: "white"
      }}
    >
      <CardContent>
        <h3 style={{ margin: 0 }}>{props.title}</h3>
      </CardContent>
    </Card>
  );
};

export default TableHeadline;
