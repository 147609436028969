import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { PtopApi } from "./services/PtopApi";
import PrintGrid from "./PrintGrid";
import ExportToCsv from "./ExportToCsv";
import SearchIcon from "./Icons/SearchIcon";
import TableHeadline from "./TableHeadline";
import ConfirmationRenderer from "./ConfirmationRenderer";
import ReceiptRenderer from "./ReceiptRenderer";
import { LoadingOverlay } from "./YearGrid/yearGridConfig";

const getColumnDefs = t => [
  {
    headerName: t("tenants_list.apartment_number"),
    field: "ApartmentNum"
  },
  {
    headerName: t("tenants_list.fee"),
    field: "Amount",
    cellStyle: { textAlign: "center" }
  },
  {
    headerName: t("tenants_list.name"),
    field: "Name"
  },
  {
    headerName: t("tenants_list.phone"),
    field: "Phone"
  },
  {
    headerName: t("tenants_list.email"),
    field: "Email"
  }
];

const TenantsList = props => {
  const { t } = useTranslation();
  let [rowData, setRowData] = useState(undefined);
  let [searchTerm, setSearchTerm] = useState("");
  let [gridApi, setGridApi] = useState(null);
  let [gridHeight, setGridHeight] = useState("70vh");

  useEffect(() => {
    let fetchData = async () => {
      let tenantsList = await PtopApi.post(
        "https://www.ptop.co.il/api/GetTenantsDetails",
        {}
      );

      setRowData(tenantsList);
    };

    fetchData();
  }, []);

  return (
    <div>
      <div style={{ marginRight: 20 }}>
        <TextField
          style={{ margin: "10px" }}
          placeholder={t("quick_search")}
          value={searchTerm}
          onChange={e => {
            setSearchTerm(e.target.value);
            gridApi.setQuickFilter(e.target.value);
          }}
        />
        <SearchIcon />
        <PrintGrid
          height="70vh"
          setGridHeight={setGridHeight}
          gridApi={gridApi}
        />
        <ExportToCsv gridApi={gridApi} fileName="רשימת דיירים" />
      </div>

      <div style={{ height: "100%", marginTop: 77, boxSizing: "border-box" }}>
        <Paper style={{ padding: 20, margin: 20 }}>
          <div style={{ position: "relative", bottom: 40 }}>
            <TableHeadline title={`${t("tenants_list.title")}`} />
            <div
              id="myGrid"
              style={{
                height: gridHeight,
                width: "100%"
              }}
              className="ag-theme-material"
            >
              <AgGridReact
                columnDefs={getColumnDefs(t)}
                rowData={rowData}
                animateRows={true}
                enableRtl={true}
                enableCellTextSelection={true}
                suppressCellSelection={true}
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  width: 100,
                  cellStyle: { textAlign: "center" }
                }}
                loadingOverlayComponent="loadingOverlay"
                frameworkComponents={{
                  confirmationRenderer: ConfirmationRenderer,
                  receiptRenderer: ReceiptRenderer,
                  loadingOverlay: LoadingOverlay
                }}
                getRowStyle={params => {
                  if (params.data.PaymentState === 4) {
                    return { background: "red" };
                  }
                  if (params.node.rowIndex % 2 === 0) {
                    return {
                      background: "#e6dfe8",
                      borderTop: "1px solid #cfcfcf",
                      borderBottom: "1px solid #cfcfcf"
                    };
                  }
                }}
                onGridReady={params => {
                  params.columnApi.autoSizeAllColumns();
                  setGridApi(params.api);
                }}
                onRowDataChanged={params => {
                  params.api.sizeColumnsToFit();
                }}
              />
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default TenantsList;
