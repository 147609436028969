import 'core-js/features/url-search-params'; // error in ie
import React, { useEffect, useState } from "react";
import "./App.css";
import YearGrid from "./YearGrid/YearGrid";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation from "./translation";
import { Route } from "react-router-dom";
import Apartment from "./Apartment";
import Month from "./Month";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import SideMenu from "./SideMenu/SideMenu";
import TenantsList from "./TenantsList";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      he: {
        translation: translation.he
      }
    },
    lng: "he",
    fallbackLng: "he",

    interpolation: {
      escapeValue: false
    }
  });

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: green
  },
  overrides: {
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#ab47bc !important"
        }
      }
    }
  },
  direction: "rtl"
});


function App() {
  let [error, setError] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("ptopId") && query.get("pToken")) {
      localStorage.setItem("ptopId", encodeURIComponent(query.get("ptopId")));
      localStorage.setItem("pToken", encodeURIComponent(query.get("pToken")));
    } else if (
      !localStorage.getItem("ptopId") ||
      !localStorage.getItem("pToken")
    ) {
      setError(true);
    }
    //todo: give no access if no local storage of params
  }, []);

  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        {/* <TopBar /> */}
        {!error && (
          <SideMenu>
            <Route path="/" exact component={YearGrid} />
            <Route path="/tenantsList" exact component={TenantsList} />
            <Route path="/apartment/:apartmentId" exact component={Apartment} />
            <Route path="/month/:year/:month" exact component={Month} />
          </SideMenu>
        )}
        {error && <h1>שגיאה!</h1>}
      </MuiThemeProvider>
    </div>
  );
}

export default App;
