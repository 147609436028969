import React from "react";

const ExportToCsv = props => {
  let exportToCsv = () => {
    props.gridApi.exportDataAsCsv({ fileName: props.fileName });
  };

  return (
    <span
      style={{
        cursor: "pointer",
        position: "relative",
        top: "10px",
        marginRight: "20px"
      }}
    >
      <img
        alt="excel export"
        onClick={exportToCsv}
        style={{ width: 20 }}
        src="/excel.png"
      />
    </span>
  );
};

export default ExportToCsv;
