import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { PtopApi } from "./services/PtopApi";
import ConfirmationRenderer from "./ConfirmationRenderer";
import ReceiptRenderer from "./ReceiptRenderer";
import PrintGrid from "./PrintGrid";
import ExportToCsv from "./ExportToCsv";
import SearchIcon from "./Icons/SearchIcon";
import Paper from "@material-ui/core/Paper";
import TableHeadline from "./TableHeadline";
import {
  ApartmentCellRenderer,
  LoadingOverlay
} from "./YearGrid/yearGridConfig";
import moment from "moment";

const getColumnDefs = t => [
  {
    headerName: t("month.ApartmentNumber"),
    field: "ApartmentNumber",
    pinned: "right",
    cellRenderer: "apartmentCellRenderer"
  },
  {
    headerName: t("month.ClientName"),
    field: "ClientName",
    pinned: "right"
  },
  {
    headerName: t("month.Amount"),
    field: "Amount",
    cellStyle: { textAlign: "center" }
  },
  {
    headerName: t("month.PaymentDate"),
    field: "PaymentDate"
  },
  {
    headerName: t("month.TenantType"),
    field: "TenantType"
  },
  {
    headerName: t("month.PlanType"),
    field: "PlanType"
  },
  {
    headerName: t("month.Comment"),
    field: "Comment"
  },
  {
    headerName: t("month.PaymentPlanId"),
    field: "PaymentPlanId"
  },
  {
    headerName: t("payemnt_confirmation"),
    field: "PaymentId",
    cellRenderer: "confirmationRenderer",
    cellStyle: { cursor: "pointer" }
  },
  {
    headerName: t("payemnt_receipt"),
    field: "PaymentId",
    cellRenderer: "receiptRenderer",
    cellStyle: { cursor: "pointer" }
  }
];

const Month = props => {
  const { t } = useTranslation();
  let [gridApi, setGridApi] = useState(null);
  let [rowData, setRowData] = useState(undefined);
  let [totalAmount, setTotalAmount] = useState("טוען נתונים");
  let [searchTerm, setSearchTerm] = useState("");
  let [gridHeight, setGridHeight] = useState("70vh");

  useEffect(() => {
    let normalizeMonth = monthData => {
      monthData.status =
        monthData.PaymentState === 4 ? t("failed") : t("success");
      monthData.PaymentDate = moment(new Date(monthData.PaymentDate)).format(
        "DD-MM-YYYY"
      );
      return monthData;
    };

    let fetchData = async () => {
      let monthData = await PtopApi.post(
        "https://www.ptop.co.il/api/GetPaymentsByMonth",
        {
          year: props.match.params.year,
          month: props.match.params.month
        }
      );

      setRowData(
        monthData.PaymentsList.map && monthData.PaymentsList.map(normalizeMonth)
      );
      setTotalAmount(monthData.TotalAmount);
    };

    fetchData();
  }, [props.match.params.year, props.match.params.month, t]);

  return (
    <div>
      <div style={{ marginRight: 20 }}>
        <TextField
          style={{ margin: "10px" }}
          placeholder={t("quick_search")}
          value={searchTerm}
          onChange={e => {
            setSearchTerm(e.target.value);
            gridApi.setQuickFilter(e.target.value);
          }}
        />
        <SearchIcon />
        <PrintGrid
          height="70vh"
          setGridHeight={setGridHeight}
          gridApi={gridApi}
        />
        <ExportToCsv gridApi={gridApi} fileName="Payments Report" />
      </div>

      <div style={{ height: "100%", marginTop: 77, boxSizing: "border-box" }}>
        <Paper style={{ padding: 20, margin: 20 }}>
          <div style={{ position: "relative", bottom: 40 }}>
            <TableHeadline
              title={`${t("month_title")} ${props.match.params.month}/${
                props.match.params.year
              } - ${totalAmount.toLocaleString("USD")} ₪`}
            />
            <div
              id="myGrid"
              style={{
                height: gridHeight,
                width: "100%",
                justifyContent: "center"
              }}
              className="ag-theme-material"
            >
              <AgGridReact
                columnDefs={getColumnDefs(t)}
                rowData={rowData}
                animateRows={true}
                enableCellTextSelection={true}
                enableRtl={true}
                suppressCellSelection={true}
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  width: 100,
                  cellStyle: { justifyContent: "center" }
                }}
                loadingOverlayComponent="loadingOverlay"
                frameworkComponents={{
                  confirmationRenderer: ConfirmationRenderer,
                  receiptRenderer: ReceiptRenderer,
                  apartmentCellRenderer: ApartmentCellRenderer,
                  loadingOverlay: LoadingOverlay
                }}
                getRowStyle={params => {
                  if (params.data.PaymentState === 4) {
                    return { background: "lightcoral" };
                  }
                  if (params.node.rowIndex % 2 === 0) {
                    return {
                      background: "#e6dfe8",
                      borderTop: "1px solid #cfcfcf",
                      borderBottom: "1px solid #cfcfcf"
                    };
                  }
                }}
                onGridReady={params => {
                  params.columnApi.autoSizeAllColumns();
                  setGridApi(params.api);
                }}
              />
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default Month;
