import React from "react";
import { useTranslation } from "react-i18next";
import { PtopApi } from "./services/PtopApi";

const ConfirmationRenderer = props => {
  const { t } = useTranslation();

  let openConfirmation = async () => {
    let confirmationLink = await PtopApi.get(
      "https://www.ptop.co.il/api/PaymentCompletedLink",
      {
        paymentId: props.value,
        email: props.data.ClientEmail || "omri@ptop.co.il"
      }
    );

    window.open(confirmationLink, "_blank");
  };

  return (
    <div
      style={{ color: "blue", textDecoration: "underline" }}
      onClick={openConfirmation}
    >
      {t("payemnt_confirmation")}
    </div>
  );
};

export default ConfirmationRenderer;
