import React from "react";
import Search from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";

const SearchIcon = props => {
  return (
    <Paper
      style={{
        padding: 10,
        borderRadius: 30,
        width: 49,
        display: "inline-block",
        position: "relative",
        top: 5
      }}
    >
      <Search style={{ position: "relative", top: 2, right: 1 }} />
    </Paper>
  );
};

export default SearchIcon;
