import React, { useState, useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { typoProps } from "./SideMenu";
import { PtopApi } from "../services/PtopApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Textfit } from "react-textfit";
import { useTranslation } from "react-i18next";

const CustomerLogo = props => {
  const { t } = useTranslation();
  let [title, setTitle] = useState("");
  let [logo, setLogo] = useState("");

  useEffect(() => {
    let getLogo = async () => {
      let customerDetails = await PtopApi.post(
        "https://www.ptop.co.il/api/GetAccountDetails",
        {}
      );

      setTitle(customerDetails.Title);
      setLogo(customerDetails.PartnerLogo);
    };
    getLogo();
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <ListItem style={{ justifyContent: "center" }} key={title}>
        {title ? (
          <React.Fragment>
            <ListItemText
              style={{ padding: 5, textAlign: "center" }}
              primaryTypographyProps={typoProps}
            >
              {/*<ListItemIcon style={{ color: "white", margin: 0 }}>*/}
              {/*<img style={{ height: 35 }} src={logo} alt="customer" />*/}
              {/*</ListItemIcon>*/}
              <Textfit mode="single">{title}</Textfit>
              {t("ptop")} {localStorage.getItem("ptopId")}
            </ListItemText>
          </React.Fragment>
        ) : (
          <CircularProgress size={20} />
        )}
      </ListItem>
      {logo ? <img alt='logo' style={{ width: "150px" }} src={logo} /> : null}
    </div>
  );
};

export default CustomerLogo;
