import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {
  getColumnDefs,
  ApartmentCellRenderer,
  MonthCellRenderer,
  PaymentCellRenderer,
  LoadingOverlay
} from "./yearGridConfig";
import { PtopApi } from "../services/PtopApi";
import PrintGrid from "../PrintGrid";
import ExportToCsv from "../ExportToCsv";
import SearchIcon from "../Icons/SearchIcon";
import TableHeadline from "../TableHeadline";
// import NotificationsIcons from "./NotificationsIcon";

const YearGrid = props => {
  const { t } = useTranslation();
  let [rowData, setRowData] = useState(undefined);
  let [year, setYear] = useState(new Date().getFullYear());
  let [searchTerm, setSearchTerm] = useState("");
  let [gridApi, setGridApi] = useState(null);
  let [gridHeight, setGridHeight] = useState("70vh");

  useEffect(() => {
    let fetchData = async () => {
      let yearData = await PtopApi.post(
        "https://www.ptop.co.il/api/GetPaymentsByYear",
        {
          year
        }
      );
      let newRowData =
        yearData.ApartmentItems &&
        Object.keys(yearData.ApartmentItems).map(ApartmentNumber => {
          let apartmentData = yearData.ApartmentItems[ApartmentNumber];
          apartmentData.payments = getPaymentsByMonth(apartmentData);
          apartmentData.ApartmentNumber = ApartmentNumber;
          return apartmentData;
        });
      setRowData(newRowData);
    };

    fetchData();
  }, [year]);

  let getPaymentsByMonth = apartmentData => {
    let futurePayments = Object.keys(apartmentData.FuturePayedMonths).map(
      monthKey => {
        return {
          monthKey,
          payment: apartmentData.FuturePayedMonths[monthKey],
          type: "FUTURE"
        };
      }
    );
    let payedMonths = Object.keys(apartmentData.PayedMonths).map(monthKey => {
      return {
        monthKey,
        payment: apartmentData.PayedMonths[monthKey],
        type: "MONTH"
      };
    });
    return futurePayments.concat(payedMonths);
  };

  return (
    <div className="year-grid" style={{ width: "100%", height: "100%" }}>
      <div style={{ marginRight: 20, fontWeight: 300, marginTop: 16 }}>
        {t("select_year")}:
        <Select
          style={{ margin: "10px" }}
          value={year}
          onChange={e => setYear(e.target.value)}
        >
          <MenuItem value={2017}>2017</MenuItem>
          <MenuItem value={2018}>2018</MenuItem>
          <MenuItem value={2019}>2019</MenuItem>
          <MenuItem value={2020}>2020</MenuItem>
          <MenuItem value={2021}>2021</MenuItem>
          <MenuItem value={2022}>2022</MenuItem>
        </Select>
        {t("quick_search")}:
        <TextField
          style={{ margin: "10px" }}
          value={searchTerm}
          onChange={e => {
            setSearchTerm(e.target.value);
            gridApi.setQuickFilter(e.target.value);
          }}
        />
        <SearchIcon />
        <PrintGrid
          height="70vh"
          setGridHeight={setGridHeight}
          gridApi={gridApi}
        />
        <ExportToCsv gridApi={gridApi} fileName="Payments Report" />
        {/*<NotificationsIcons />*/}
        <img
          style={{
            height: 55,
            float: "left",
            position: "relative",
            top: 2,
            left: 36
          }}
          src="/logo.png"
          alt="logo"
        />
      </div>
      <div style={{ height: "100%", marginTop: 57, boxSizing: "border-box" }}>
        <Paper style={{ padding: 20, margin: 20 }}>
          <div style={{ position: "relative", bottom: 40 }}>
            <TableHeadline title={`${t("year_title")} ${year}`} />
            <div
              id="myGrid"
              style={{
                height: gridHeight,
                width: "100%",
                justifyContent: "center"
              }}
              className="ag-theme-material"
            >
              <AgGridReact
                columnDefs={getColumnDefs(t, year)}
                rowData={rowData}
                animateRows={true}
                enableRtl={true}
                enableCellTextSelection={true}
                suppressCellSelection={true}
                getRowStyle={params => {
                  if (params.node.rowIndex % 2 === 0) {
                    return {
                      background: "#e6dfe8",
                      borderTop: "1px solid #cfcfcf",
                      borderBottom: "1px solid #cfcfcf"
                    };
                  }
                }}
                loadingOverlayComponent="loadingOverlay"
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  cellStyle: { justifyContent: "center" }
                }}
                frameworkComponents={{
                  apartmentCellRenderer: ApartmentCellRenderer,
                  agColumnHeader: MonthCellRenderer,
                  paymentCellRenderer: PaymentCellRenderer,
                  loadingOverlay: LoadingOverlay
                }}
                onGridReady={params => {
                  params.api.sizeColumnsToFit();
                  setGridApi(params.api);
                }}
                onRowDataChanged={params => {
                  params.api.sizeColumnsToFit();
                }}
              />
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default YearGrid;
