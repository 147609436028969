import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { PtopApi } from "./services/PtopApi";
import ConfirmationRenderer from "./ConfirmationRenderer";
import ReceiptRenderer from "./ReceiptRenderer";
import PrintGrid from "./PrintGrid";
import ExportToCsv from "./ExportToCsv";
import SearchIcon from "./Icons/SearchIcon";
import Paper from "@material-ui/core/Paper";
import TableHeadline from "./TableHeadline";
import { LoadingOverlay } from "./YearGrid/yearGridConfig";
import moment from "moment";

const getColumnDefs = t => [
  {
    headerName: t("apartment.paymentDate"),
    field: "PaymentDate"
  },
  {
    headerName: t("month.ClientName"),
    field: "ClientName"
  },
  {
    headerName: t("apartment.amount"),
    field: "Amount",
    cellStyle: { textAlign: "center" }
  },
  {
    headerName: t("apartment.cardLast4Digits"),
    field: "CardLast4Digits"
  },
  {
    headerName: t("apartment.planType"),
    field: "PlanType"
  },
  {
    headerName: t("apartment.comments"),
    field: "Comment"
  },
  {
    headerName: t("apartment.tenantType"),
    field: "TenantType"
  },
  {
    headerName: t("apartment.PaymentPlanId"),
    field: "PaymentPlanId"
  },
  {
    headerName: t("payemnt_confirmation"),
    field: "PaymentId",
    cellRenderer: "confirmationRenderer",
    cellStyle: { cursor: "pointer" }
  },
  {
    headerName: t("payemnt_receipt"),
    field: "PaymentId",
    cellRenderer: "receiptRenderer",
    cellStyle: { cursor: "pointer" }
  }
];

const Apartment = props => {
  const { t } = useTranslation();
  let [gridApi, setGridApi] = useState(null);
  let [searchTerm, setSearchTerm] = useState("");
  let [rowData, setRowData] = useState(undefined);
  let [gridHeight, setGridHeight] = useState("70vh");

  let apartmentId = decodeURIComponent(props.match.params.apartmentId);

  useEffect(() => {
    let normalizeApartment = apartmentData => {
      apartmentData.PaymentDate = moment(apartmentData.PaymentDate).format(
        "DD-MM-YYYY"
      );
      apartmentData.status =
        apartmentData.PaymentState === 4 ? t("failed") : t("success");
      return apartmentData;
    };

    let fetchData = async () => {
      let apartmentData = await PtopApi.post(
        "https://www.ptop.co.il/api/GetPaymentsByApartment",
        {
          apartment: apartmentId
        }
      );
      setRowData(
        apartmentData.paymentResults &&
          apartmentData.paymentResults.map(normalizeApartment)
      );
    };

    fetchData();
  }, [apartmentId, t]);

  return (
    <div>
      <div style={{ marginRight: 20 }}>
        <TextField
          style={{ margin: "10px" }}
          placeholder={t("quick_search")}
          value={searchTerm}
          onChange={e => {
            setSearchTerm(e.target.value);
            gridApi.setQuickFilter(e.target.value);
          }}
        />
        <SearchIcon />
        <PrintGrid
          height="70vh"
          setGridHeight={setGridHeight}
          gridApi={gridApi}
        />
        <ExportToCsv gridApi={gridApi} fileName="Payments Report" />
      </div>

      <div style={{ height: "100%", marginTop: 77, boxSizing: "border-box" }}>
        <Paper style={{ padding: 20, margin: 20 }}>
          <div style={{ position: "relative", bottom: 40 }}>
            <TableHeadline title={`${t("apartment_title")} ${apartmentId}`} />
            <div
              id="myGrid"
              style={{
                height: gridHeight,
                width: "100%"
              }}
              className="ag-theme-material"
            >
              <AgGridReact
                columnDefs={getColumnDefs(t)}
                rowData={rowData}
                animateRows={true}
                enableRtl={true}
                enableCellTextSelection={true}
                suppressCellSelection={true}
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  width: 100,
                  cellStyle: { textAlign: "center" }
                }}
                loadingOverlayComponent="loadingOverlay"
                frameworkComponents={{
                  confirmationRenderer: ConfirmationRenderer,
                  receiptRenderer: ReceiptRenderer,
                  loadingOverlay: LoadingOverlay
                }}
                getRowStyle={params => {
                  if (params.data.PaymentState === 4) {
                    return { background: "red" };
                  }
                  if (params.node.rowIndex % 2 === 0) {
                    return {
                      background: "#e6dfe8",
                      borderTop: "1px solid #cfcfcf",
                      borderBottom: "1px solid #cfcfcf"
                    };
                  }
                }}
                onGridReady={params => {
                  params.columnApi.autoSizeAllColumns();
                  setGridApi(params.api);
                }}
              />
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default Apartment;
