import React from "react";
import Print from "@material-ui/icons/Print";

const PrintGrid = ({ gridApi, setGridHeight, height }) => {
  let printGrid = () => {
    gridApi.setDomLayout("print");
    setGridHeight("");

    setTimeout(function() {
      window.print();
      gridApi.setDomLayout("");
      setGridHeight(height);
    }, 2000);
  };

  return (
    <Print
      style={{
        cursor: "pointer",
        position: "relative",
        top: "11px",
        marginRight: "20px"
      }}
      onClick={printGrid}
    />
  );
};

export default PrintGrid;
