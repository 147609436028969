import React from "react";
import { useTranslation } from "react-i18next";
import { PtopApi } from "./services/PtopApi";

const ReceiptRenderer = props => {
  const { t } = useTranslation();

  let openReceipt = async () => {
    let receiptLink = await PtopApi.post(
      "https://www.ptop.co.il/api/GetDownloadableInvoice",
      {
        paymentId: props.value
      }
    );

    window.open(receiptLink, "_blank");
  };

  return (
    <div
      style={{ color: "blue", textDecoration: "underline" }}
      onClick={openReceipt}
    >
      {t("payemnt_receipt")}
    </div>
  );
};

export default ReceiptRenderer;
