import React from "react";
import { Link } from "react-router-dom";
import ThumbUp from "@material-ui/icons/ThumbUp";
import ThumbUpOutlined from "@material-ui/icons/ThumbUpOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";

const ApartmentCellRenderer = props => {
  return (
    <Link
      style={{ fontSize: 15, color: "#61616a" }}
      to={"/apartment/" + encodeURIComponent(props.value)}
    >
      {props.value}
    </Link>
  );
};

const LoadingOverlay = props => {
  return <CircularProgress />;
};

const MonthCellRenderer = props => {
  if (props.month) {
    return (
      <Link
        style={{ color: "#61616a" }}
        onTouchStart={e => e.target.click()}
        to={`/month/${props.year}/${props.month}`}
      >
        {props.displayName}
      </Link>
    );
  } else {
    return <div>{props.displayName}</div>;
  }
};

let valueGetter = params => {
  let month = params.colDef.cellRendererParams.month;
  let payments = params.data.payments || [];
  let payment = payments.find(payment => payment.monthKey === month);
  if (payment) {
    if (payment.type === "FUTURE") {
      // payment.toString = () => `${payment.payment.toString()} (עתידי)`;
      payment.toString = () => payment.payment.toString();
    } else {
      payment.toString = () => payment.payment.toString();
    }
  }

  return payment;
};

const PaymentCellRenderer = props => {
  let monthlyPay = props.value;
  let showFilledIcon = monthlyPay && monthlyPay.type !== "FUTURE";
  return (
    <div style={{ fontSize: 16 }}>
      {monthlyPay && showFilledIcon && (
        <ThumbUp
          style={{
            color: "#48b030",
            position: "absolute",
            top: "5px",
            marginRight: -17,
            fontSize: "15px"
          }}
        />
      )}
      {monthlyPay && !showFilledIcon && (
        <ThumbUpOutlined
          style={{
            position: "absolute",
            top: "5px",
            marginRight: -17,
            fontSize: "15px"
          }}
        />
      )}
      {monthlyPay && monthlyPay.payment}{" "}
    </div>
  );
};

const getColumnDefs = (t, year) => [
  {
    headerName: t("apartment_number"),
    field: "ApartmentNumber",
    pinned: "right",
    cellRenderer: "apartmentCellRenderer",
    headerComponentParams: { year }
  },
  {
    headerName: t("tenant_name"),
    field: "TenantName",
    pinned: "right"
  },
  {
    headerName: t("months.jan"),
    headerComponentParams: {
      year,
      month: "1"
    },
    cellRenderer: "paymentCellRenderer",
    cellRendererParams: { month: "1" },
    valueGetter
  },
  {
    headerName: t("months.feb"),
    headerComponentParams: { year, month: 2 },
    cellRenderer: "paymentCellRenderer",
    cellRendererParams: { month: "2" },
    valueGetter
  },
  {
    headerName: t("months.march"),
    headerComponentParams: { year, month: 3 },
    cellRenderer: "paymentCellRenderer",
    cellRendererParams: { month: "3" },
    valueGetter
  },
  {
    headerName: t("months.april"),
    headerComponentParams: { year, month: 4 },
    cellRenderer: "paymentCellRenderer",
    cellRendererParams: { month: "4" },
    valueGetter
  },
  {
    headerName: t("months.may"),
    headerComponentParams: { year, month: 5 },
    cellRenderer: "paymentCellRenderer",
    cellRendererParams: { month: "5" },
    valueGetter
  },
  {
    headerName: t("months.june"),
    headerComponentParams: { year, month: 6 },
    cellRenderer: "paymentCellRenderer",
    cellRendererParams: { month: "6" },
    valueGetter
  },
  {
    headerName: t("months.july"),
    headerComponentParams: { year, month: 7 },
    cellRenderer: "paymentCellRenderer",
    cellRendererParams: { month: "7" },
    valueGetter
  },
  {
    headerName: t("months.august"),
    headerComponentParams: { year, month: 8 },
    cellRenderer: "paymentCellRenderer",
    cellRendererParams: { month: "8" },
    valueGetter
  },
  {
    headerName: t("months.sept"),
    headerComponentParams: { year, month: 9 },
    cellRenderer: "paymentCellRenderer",
    cellRendererParams: { month: "9" },
    valueGetter
  },
  {
    headerName: t("months.oct"),
    headerComponentParams: { year, month: 10 },
    cellRenderer: "paymentCellRenderer",
    cellRendererParams: { month: "10" },
    valueGetter
  },
  {
    headerName: t("months.nov"),
    headerComponentParams: { year, month: 11 },
    cellRenderer: "paymentCellRenderer",
    cellRendererParams: { month: "11" },
    valueGetter
  },
  {
    headerName: t("months.dec"),
    headerComponentParams: { year, month: 12 },
    cellRenderer: "paymentCellRenderer",
    cellRendererParams: { month: "12" },
    valueGetter
  }
];

export {
  getColumnDefs,
  ApartmentCellRenderer,
  MonthCellRenderer,
  PaymentCellRenderer,
  LoadingOverlay
};
